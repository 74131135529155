<template>
  <span class="template-editor-wrapper">
    <!--<b-dropdown class="view-toolbar-item" aria-role="list" position="is-bottom-left"
      @active-change="loadTemplate">-->
      <a class="top-view-button" @click="showTemplateEditor = !showTemplateEditor">
        <i class="mdi mdi-database"/>Affichage
        <i class="mdi mdi-menu-down" />
      </a>
      <portal to="template-editor-content">
        <div :class="`template-editor-overlay ${showTemplateEditor ? 'shown' : 'hidden'}`" />
        <div :class="`template-editor ${showTemplateEditor ? 'shown' : 'hidden'}`" v-if="editedModel">
          <div v-if="showTemplateEditor" >
            <span style="float: right" v-if="partEdited === 'template'">
              Code
              <b-switch
                size="is-small"
                style="padding-left: 10px;"
                v-model="editedModel.templateEditor"
                false-value="CODE"
                true-value="WYSIWYG"
              />
              Visual
            </span>
            <b-message v-if="!editedModel.schema && editedModel.templateEditor === 'WYSIWYG'" type="is-warning" has-icon>
              <b>Aucun schéma associé à la vue</b><br/>
              Selectionnez un schéma de données (onglet <b><i class="mdi mdi-database" />Données</b>) pour modifier la vue.
            </b-message>
            <div v-else-if="partEdited === 'template' && editedModel.templateEditor === 'WYSIWYG'">
              <div class="columns">
                <div style="flex-grow: 1">
                  <b>Champs disponibles</b>
                  <draggable v-if="availableFields"
                    :list="availableFields"
                    class="available-fields"
                    :group="{ name: 'fields', pull: 'clone', put: false }"
                    :clone="cloneField"
                  >
                    <FieldCard
                      v-for="(f, i) in availableFields"
                      :key="f.model"
                      :value="f"
                      :schema="schemaFor(f.type)"
                    />
                  </draggable>
                </div>
                <div style="width: 400px;">
                  <b>Divers</b>
                  <draggable v-if="renderComponentsWithoutField"
                    :list="renderComponentsWithoutField"
                    class="available-components"
                    :group="{ name: 'fields', pull: 'clone', put: false }"
                    :clone="cloneField"
                  >
                    <FieldCard
                      v-for="(c, i) in renderComponentsWithoutField"
                      :key="c.renderComponent"
                      :value="c"
                      :schema="undefined"
                      @input="availableFields[i] = $event"
                    />
                  </draggable>
                </div>
              </div>
              <div class="option-tabs">
                <span class="option-tab">
                  <b-dropdown v-model="selectedComponent" aria-role="list">
                    <span slot="trigger">
                      <span>{{selectedComponentLabel}}</span>
                      <i class="mdi mdi-menu-down" />
                    </span>
                    <b-dropdown-item v-for="c in availableComponentsWrappers" :key="c.name" :value="c.name" aria-role="listitem">
                      {{c.label}}
                    </b-dropdown-item>
                  </b-dropdown>
                </span>
                <span class="option-tab">
                  <b-dropdown aria-role="list" v-model="editedModel.clickAction">
                    <span slot="trigger">
                      Action au clic : {{editedModel.clickAction || 'edit'}}
                      <i class="mdi mdi-menu-down" />
                    </span>
                    <b-dropdown-item aria-role="listitem" value="edit">Edit</b-dropdown-item>
                    <b-dropdown-item aria-role="listitem" value="see">See</b-dropdown-item>
                    <b-dropdown-item aria-role="listitem" value="none">Nothing</b-dropdown-item>
                  </b-dropdown>
                </span>
              </div>
              <component :is="selectedComponent" class="item-component-preview">
                <draggable group="fields" class="field-slot" :list="editedModel.templateComponent ? editedModel.templateComponent.fields : []">
                  <span v-if="!editedModel.templateComponent || !editedModel.templateComponent.fields || editedModel.templateComponent.fields.length === 0" class="no-fields-placeholder">
                    Déplacez ici des champs
                  </span>
                  <FieldCard
                    v-else
                    v-for="(f, i) in editedModel.templateComponent.fields"
                    :key="f.model"
                    :value="f"
                    :schema="schemaFor(f.type)"
                    :class="f.styles.join(' ')"
                    @input="inputOnFieldCard(i, $event)"
                    @delete="editedModel.templateComponent.fields.splice(i, 1)"
                  />
                </draggable>
              </component>
            </div>
            <div v-else-if="partEdited === 'template'">
              <codemirror
                v-model="valueTemplate"
                :options="editorOptions"
                height="300px"
                class="editor field-editor small field-footer"
              />
            </div>
            <div style="float: right; padding-top: 12px;">
              <a class="button" @click="cancelEdition">
                Annuler
              </a>
              <a :class="`button is-primary ${saving ? 'is-loading' : ''}`" style="margin-left: 14px;" @click="applyEdition">
                Appliquer
              </a>
            </div>
          </div>
        </div>
      </portal>
    </span>
  </template>
<script>
import Modal from '@/components/modals/Modal';
import vfgfields from '@/core/vfgfields';
import recordTemplates from '@/components/record-templates/index';
import codemirror from '@/components/ui/Codemirror';

import renderComponents from '@/components/record-templates/render-components/index';

import deepClone from '@/core/utils/deepClone';
import draggable from 'vuedraggable';
import { debounce } from 'vue-debounce';
import FieldCard from './TemplateEditorModal/FieldCard';

export default {
  name: 'TemplateEditorModal',
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  components: {
    codemirror,
    Modal,
    draggable,
    FieldCard,
  },
  data() {
    return {
      saving: false,
      dropdownLabels: {
        template: 'Affichage des enregistrements',
        header: 'Entête',
        footer: 'Pied de page',
      },
      editedModel: {},
      editedModelBackup: undefined,
      partEdited: 'template',
      showTemplateEditor: false,
      editorOptions: {
        tabSize: 2,
        lineWrapping: true,
        mode: { name: 'vue' },
        theme: 'idea',
        lineNumbers: true,
        line: true,
        keyMap: 'sublime',
        autoCloseBrackets: true,
      },
      recordTemplates,
      displayedFields: [],
      renderComponentsWithoutField: renderComponents.filter((c) => c.needField !== true).map((c) => ({ renderComponent: c.simpleName })),
      availableComponentsWrappers: recordTemplates,
      valueTemplateDebouncedTempVal: undefined,
      debouncedSetTemplate: debounce(() => {
        this.editedModel.template = this.valueTemplateDebouncedTempVal;
      }, 500),
    };
  },
  watch: {
    showTemplateEditor: 'loadTemplate',
    editedModel: {
      handler (v) {
        this.$store.commit('currentPanel/setCurrentPanel', v);
      },
      deep: true
    }
  },
  computed: {
    valueTemplate: {
      get() {
        if (this.editedModel) {
          return this.editedModel.template;
        }
      },
      set(v) {
        this.valueTemplateDebouncedTempVal = v;
        this.debouncedSetTemplate();
      },
    },
    selectedComponent: {
      get() {
        if(this.editedModel) {
          if (!this.editedModel.templateComponent){
            this.editedModel.templateComponent = {};
          }
          if (!this.editedModel.templateComponent.fields) {
            this.editedModel.templateComponent.fields = [];
          }
          if (!this.editedModel.templateComponent.name) {
            this.editedModel.templateComponent.name = 'CardRecordTemplate';
          }
          return this.editedModel.templateComponent.name;
        }
        return 'CardRecordTemplate';
      },
      set(v) {
        if (!this.editedModel.selectedComponent) {
          this.editedModel.templateComponent = { name: v, fields: [] };
        } else {
          this.editedModel.templateComponent.name = v;
        }
        this.editedModel.templateComponent = deepClone(this.editedModel.templateComponent);
      },
    },
    availableFields() {
      if (this.editedModel && this.editedModel.schema) {
        return this.getSchemaByName(this.editedModel.schema).fields;
      } return [];
    },
    selectedComponentLabel() {
      if (this.selectedComponent) {
        const selectedComponentObject = Object.values(recordTemplates).filter((c) => c.name === this.selectedComponent)[0];
        if (selectedComponentObject) {
          return selectedComponentObject.label;
        }
      }
      return 'Unknown component';
    },
  },
  methods: {
    loadTemplate() {
      this.editedModel = this.$store.state.currentPanel.currentPanel;
      this.editedModelBackup = deepClone(this.editedModel);
    },
    cancelEdition () {
      this.editedModel = deepClone(this.editedModelBackup);
      this.showTemplateEditor = false;
    },
    async applyEdition () {
      this.saving = true;
      const res = await this.$store.dispatch('abstractElements/saveObject', {
        collection: 'panels',
        object: this.editedModel,
      });
      this.$store.commit('currentPanel/setCurrentPanel', res.payload.data.document);
      this.editedModelBackup = deepClone(this.editedModel);
      this.saving = false;
      this.showTemplateEditor = false;
    },
    inputOnFieldCard(i, $event) {
      this.editedModel.templateComponent.fields[i] = $event;
      this.editedModel.templateComponent.fields = [...this.editedModel.templateComponent.fields];
      //this.editedModel = deepClone(this.editedModel);
    },
    schemaFor(type) {
      return vfgfields.fields.filter((f) => f.originalLabel === type)[0] || undefined;
    },
    getSchemaByName(schemaName) {
      if (!this.$store.state.abstractElements.objects || !this.$store.state.abstractElements.objects.schemas) {
        return {};
      }

      const schemas = this.$store.state.abstractElements.objects.schemas.objects;
      if (!schemas) { return {}; }
      return schemas.find((s) => s.name === schemaName) || {};
    },
    cloneField(f) {
      const s = this.schemaFor(f.type);
      this.$nextTick(() => {
        this.editedModel = deepClone(this.editedModel);
      });
      return {
        type: f.type,
        model: f.model,
        label: f.label,
        styles: [],
        renderComponent: s ? s.renderComponent : f.renderComponent,
      };
    },
  },
};
</script>
<style scoped>
.top-view-button {
  display: inline-block;
  padding: 8px 12px;
}
.template-editor-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: #00000033;
  z-index: 1000000000;
  transition: background 0.2s;
}
.template-editor-overlay.hidden {
  pointer-events: none;
  background: #00000000;
}
.template-editor-wrapper {
  height:420px;

}
/*
.template-editor {
position: fixed;
top: 80px;
left: calc(50% - 650px);
width: 900px;
height: 370px;
z-index: 100002;
background: white;
border-radius: 10px;
padding: 20px;
}
 */
.template-editor {
  width: 800px;
  padding: 20px;
  height: 450px;
  transition: height 0.2s;
}
.template-editor.hidden {
  height: 0;
  padding: 0;
}

.template-editor > div {
  z-index: 1000000001;
  position: absolute;
  height: 400px;
  background: white;
  border-radius: 20px;
  padding: 20px;
  width: 940px;
}
.available-fields {
  height: 120px;
}
.no-fields-placeholder {
  width: 600px;
  height: 0;
  position: absolute;
  color: #aaa;
}
.template-editor >>> .field-slot {
  padding:10px;
  border: 3px dashed #ccc;
  min-height: 54px;
  min-width: 200px;
  display: inline-flex;
  flex-grow: 1;
}
.template-editor >>> .field-card {
  padding: 2px;
  padding-left: 6px;
  padding-right: 6px;
  cursor: grabbing;
  display: inline-block;
  background: white;
}

.available-components .field-card, .available-fields .field-card {
  border: 1px solid #ddd;
  background: #eee;
  opacity: 0.6;
}
.available-components .field-card:hover, .available-fields .field-card:hover {
  opacity: 1;
}

.template-editor >>> .field-card .draggable-field-label {
  display: inline;
}
.template-editor >>> .field-card .menu {
  display: none;
  padding: 2px;
}
.template-editor >>> .field-slot .field-card .menu {
  display: inline;
}
.template-editor >>> .field-slot .field-card .menu .dropdown-trigger {
  display: inline;
}

.item-component-preview {
  box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.2);
}

.option-tabs {
  margin-top: 20px;
  display: inline-block;
}
.option-tab {
  margin-left: 10px;
}
.option-tab >>> .dropdown-trigger {
  background: #ddd;
  border-left: 1px solid #aaa;
  border-top: 1px solid #aaa;
  border-right: 1px solid #aaa;
  border-radius: 4px 4px 0 0;
  font-size: 80%;
  padding: 4px;
  cursor: pointer;
  opacity: 0.5;
}
.option-tab:hover >>> .dropdown-trigger {
  opacity: 1;
}
.columns {
  margin: 0;
  margin-bottom: 20px;
}
</style>
