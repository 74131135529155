<template>
  <div class="card">
    <div class="card-content">
      <slot />
    </div>
  </div>
</template>
<script>
export default {
  name: 'CardRecordTemplate',
  label: 'Card',
};
</script>
<style scoped>
.card-content {
  display: flex;
  flex-wrap: wrap;
}
</style>
