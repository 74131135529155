<template>
  <div class="field-card">
    <span class="handle">
      <template v-if="schema">
        <i :class="`mdi mdi-${schema ? schema.icon : 'bug'}`" />
        <span class="draggable-field-label">{{value.label}}</span>
      </template>
      <template v-else>
        <component :is="`render-component-${value.renderComponent}`" :field="value" />
      </template>
    </span>
    <FieldDropdown class="field-dropdown" :value="value" @input="$emit('input', $event)" @delete="$emit('delete')"/>
  </div>
</template>
<script>
import FieldDropdown from './FieldDropdown';

export default {
  name: 'FieldCard',
  components: {
    FieldDropdown,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
    schema: {
      type: Object,
      default: undefined,
    },
  },
};
</script>
<style scoped>
.field-card .field-dropdown {
  opacity: 0;
}
.field-card:hover .field-dropdown {
  opacity:1;
}
</style>
