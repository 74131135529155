<template>
  <b-dropdown aria-role="list" class="menu" append-to-body>
    <a class="dropdown-trigger" slot="trigger" slot-scope="{ active }">
      <i class="mdi mdi-dots-vertical" />
    </a>
    <b-menu>
      <b-menu-list>
        <li>
          <b-dropdown aria-role="list" :triggers="['hover']" class="template-editor-field sub-dropdown" position="is-bottom-right">
            <a class="" slot="trigger">
              <span>Styles</span>
              <i class="mdi mdi-menu-right" style="float: right" />
            </a>
            <a v-for="s in styles" :key="s" aria-role="listitem" :class="value.styles && value.styles.includes(s) ? 'is-active' : ''" @click="toggleStyle(s)">
              <span :class="s">{{s}}</span>
            </a>
          </b-dropdown>
        </li>
        <li>
          <b-dropdown aria-role="list" :triggers="['hover']" class="template-editor-field sub-dropdown renderer-properties" position="is-bottom-right">
            <a class="" slot="trigger">
              <span>Render</span>
              <i class="mdi mdi-menu-right" style="float: right" />
            </a>
            <div v-if="value.model" style="width: 200px;border-right: 1px solid #aaa">
              <a aria-role="listitem" :class="value.renderComponent === undefined ? 'is-active' : ''" @click="setRenderComponent(undefined)">
                As-is
              </a>
              <a v-for="f in renderComponentsWithField" :key="f.name" aria-role="listitem" :class="value.renderComponent === f.simpleName ? 'is-active' : ''" @click="setRenderComponent(f.simpleName)">
                <span>{{f.simpleName}}</span>
              </a>
            </div>
            <div v-if="currentRenderComponentObject && currentRenderComponentObject.options" style="padding: 10px;">
              <h5 class="title is-5">Options</h5>
              <vue-form-generator
                :schema="currentRenderComponentObject.options"
                :model="value"
                class="form"
                tag="div"
              />
            </div>
          </b-dropdown>
        </li>
        <li>
          <a @click="$emit('delete')">
            <i class="mdi mdi-delete"/>
            Delete
          </a>
        </li>
      </b-menu-item>
    </b-menu-list>
  </b-menu>
</b-dropdown>
</template>

<script>
import renderComponents from '@/components/record-templates/render-components/index';

export default {
  name: 'TemplateEditorModalFieldDropdown',
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      renderComponents,
      renderComponentsWithField: renderComponents.filter((c) => c.needField === true),
      styles: [
        'tag',
        'expand',
        'full-line',
      ],
    };
  },
  computed: {
    currentRenderComponentObject() {
      if (this.value.renderComponent) {
        return this.renderComponents.filter((c) => c.simpleName === this.value.renderComponent)[0];
      }
    },
  },
  methods: {
    toggleStyle(s) {
      const v = this.value;
      if (v.styles && v.styles.includes(s)) {
        v.styles.splice(v.styles.indexOf(s), 1);
      } else {
        if (!v.styles) {
          v.styles = [];
        }
        v.styles.push(s);
      }
      this.value.styles = [...v.styles];
      this.$emit('input', { ...this.value });
    },
    setRenderComponent(name) {
      this.$set(this.value, 'renderComponent', name);
    },
  },
};
</script>
<style scoped>
.menu {}
.sub-dropdown, .sub-dropdown >>> .dropdown-trigger {
  width: 100%;
}
</style>
<style>
.sub-dropdown .dropdown-menu {
  margin-left: 192px;
  margin-top: -40px;
}
.renderer-properties.sub-dropdown .dropdown-content {
  width: 500px;
  display: flex;
}

</style>
